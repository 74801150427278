import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import React, { Fragment, Component, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { apiService, ResultD, ArtLink } from './api';


const contentStyles = (theme : Theme) => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  toolbarMargin: theme.mixins.toolbar
});


export enum DisplayMode {
  last,
  a10,
  info,
}

export type Card = {
  userId : number,
  id : number,
  title  :string,
  body_id : number,
  body_title : string,
  body_content : string,
  body_more : number
}

interface CardProps {
    key : number;
    card : Card;
    active? : Card;
    handleClickCard? : any;    
    mode : DisplayMode;
    category? : string;
    id? : string;
    classes? : any;
  }
  
  interface CardState {
    mode : DisplayMode;
    id? : string;
    className : string;
    title : string;
    anchor: Element | null;
    arts? : ArtLink[];
  }

class CardBox extends React.Component<CardProps, CardState> {
  static defaultProps = {
    mode: DisplayMode.last
  };

    constructor(props: CardProps) {
      super(props as any); 
      this.state = { mode: this.props.mode,
          className : 'box',
          title : this.props.card.title,
          id : this.props.id,
          anchor: null };
    }

  closeMenu = () => this.setState({ ...this.state, anchor: null });

  async getData(id : string) {
    apiService.getD({},id)
        .then((res: ResultD) => {
            this.setState({ ...this.state, arts: res.art,
              mode : DisplayMode.a10, anchor: null   })
        }
        )
        .catch((err: any) => {
            alert('Błąd odczytu' + JSON.stringify(err));
        }
        );
}

  handleClick = (event : MouseEvent) => {
      //event.type = click
//            alert(JSON.stringify(event.detail));
            this.setState({ ...this.state, anchor: null })
          }
  handleMenuClick = (mode : DisplayMode) => {
    if ((mode == DisplayMode.a10) && (!this.state.arts)) {
      this.getData(this.props.card.id.toString())
    }
    else
      this.setState({ ...this.state, mode : mode, anchor: null  })
  }
            
            
   menuItems = <Fragment>
   <MenuItem onClick={()=>this.handleMenuClick(DisplayMode.last)}>
     Aktualny
   </MenuItem>
   <MenuItem onClick={()=>this.handleMenuClick(DisplayMode.a10)}>
     Ostatnie artykuły
   </MenuItem>
 </Fragment>


/*
       <BoxBar id={""+this.props.card.id} 
        className="content"
        title={this.props.card.title}  />


*/

render() {
    const {  key, classes } = this.props;  
    const button=<Button variant="contained" color="primary" 
          href={"http://argumenty.net/"+this.props.card.body_id}
          target="_blank"  >
        węcej</Button>
    let body : any;
    if (this.state.mode == DisplayMode.a10)  {
      const l = this.state.arts?.map(
        (a : ArtLink) => {
          return  <ListItem button component="a" 
          href={"http://argumenty.net/"+a.id} 
          target="_blank">
         <ListItemText 
         primary={<Typography variant="h6" 
                   style={{ color: '#ff6f00' }}>{a.title}</Typography>}         
         />
         </ListItem>
        }
      )
      body =<List component="nav" aria-label="secondary mailbox folders">
        {l}
        </List>
    } else {
      body = <Fragment>
    <Typography variant="h3" component="h3" style={{padding:"10px"}}>
    {this.props.card.body_title}
    </Typography>
    <div dangerouslySetInnerHTML={{__html:this.props.card.body_content}} 
           style={{padding: "10px"}}
           className={"art_body"} />
    <div>
        { this.props.card.body_more>0? 
        button:<hr />}
    </div> 
    </Fragment>
    }
    
    return(
        <Box  className={"content"} boxShadow={1} >

        <div className={(this.props.card === this.props.active) ? 
            "card active" : "card"} 
        onClick={()=>this.props.handleClickCard(this.props.card)}>



          <AppBar  position="static" color="default" elevation={1}>
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={e =>
                  this.setState(
                    {...this.state, 
                      anchor: e.currentTarget })
                }
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={this.state.anchor}
                open={Boolean(this.state.anchor)}
                onClose={this.closeMenu}
              >
                { this.menuItems }
              </Menu>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.flex}
              >
                {this.state.title}
              </Typography>
            </Toolbar>
          </AppBar>
{body}

       </div>
       </Box>       
      )
    }
}

export default withStyles(contentStyles)(CardBox);

  
/*
          <div className={classes.toolbarMargin} />
  
        <div className={classes.root} >
          treść

          <img
    className="image"
    src={`https://picsum.photos/400/300`}
    height="150"
    alt=""
  />
          </div>

*/