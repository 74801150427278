import React from 'react';
import ReactDOM from 'react-dom';
import { 
//    Route, 
    Router, Switch
//    , Redirect 
} from 'react-router-dom';
import App from './App';

import { createBrowserHistory } from 'history';

const hist = createBrowserHistory();


//ReactDOM.render(<App cards={[]} />, document.getElementById('root'));



ReactDOM.render(
   <Router history={hist}>
    <Switch>
      <App cards={[]} />
    </Switch>
   </Router>
,
  document.getElementById('root'),
);

