import React, { Component } from 'react';
import 'normalize.css'; 
import './styles.css';
import CardBox, { Card, DisplayMode } from './CardBox';

type LayoutProps = {
    cards : Card[]
    style: string,
    columns: number,
    active? : Card,
    handleClickCard : any
}
type CardsProps = {
    cards : Card[],
    layout: string,
    columns: number,
    maxCards: number
}
type CardsState = {
    cardActive? : Card,
}

const Layout = (props : LayoutProps) => 
    <div className={ 'layout ' + props.style } 
            style={{"columnCount" : props.columns}}>
        {props.cards.map( (card, index) => 
            <CardBox key={index} card={card} 
             active={props.active} 
             mode={DisplayMode.last}
             handleClickCard={props.handleClickCard}/>
            
        )}
    </div>

class Cards extends Component<CardsProps, CardsState> {
    static defaultProps = {
        layout: "masonry",
        cardActive : undefined,
        columns: 3,
        maxCards: 20
      };

    constructor(props : CardsProps, state: CardsState) {
        super(props, state);
        this.state = {
            cardActive : undefined,
        }
    }
    
    handleClickCard = (card : Card) => {
        if (this.state.cardActive) 
          if (this.state.cardActive !== card) 
            this.setState({
                ...this.state,
                cardActive: card
            })
        else
          this.setState({
               ...this.state,
               cardActive : undefined
            })
    }

    render() {
        let CARDS = this.props.cards;
        return (
                <div className="layout--wrapper">
                    { CARDS && <Layout style={this.props.layout} 
                         columns={this.props.columns} 
                         cards={CARDS} 
                         active={this.state.cardActive} 
                         handleClickCard={this.handleClickCard} /> }
                </div>
            
        );
    }
}

export default Cards;

/*
           <Content id={""+this.props.card.id} 
           className="content"
           title={""+this.props.card.id}  />

*/