import React, { Component } from 'react';
import 'normalize.css';
import './styles.css';
import Cards from './Cards';
import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';

import { apiService, ResultA, Art } from './api';

//import Election from './Election';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import {isMobile} from 'react-device-detect';
import { Button } from '@material-ui/core';
import { Card } from './CardBox';

/*import styled from 'styled-components/macro'
const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`
*/
/*
import { useLocation } from 'react-router-dom'

function HeaderView() {
  let location = useLocation();
  console.log(location.pathname);
return <span>Path : {location.pathname} {location.hash}</span>
}
*/

function getHash() {
  return window.location.hash
}
  

const appStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuTopItem: {
        fontSize: "1.5em",
        padding: "0.2em",
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
}
)

interface AppProps {
    cards: [],
    classes?: any
}
interface AppState {
    subpage: number,
    cardsOG: Card[],
    layout: string,
    columns: number,
}

class App extends Component<AppProps, AppState> {
   
    constructor(props: AppProps) {
        super(props);
        let subpage= (getHash()=='#e')?2:1
        if (isMobile) {
            this.state = {
                subpage: subpage,
                cardsOG: [],
                layout: "flat",
                columns: 1,
            }
            }
        else {
            this.state = {
                subpage: subpage,
                cardsOG: [],
                layout: "masonry",
                columns: 3,
            }
    
        }

    }

    async getData() {
        apiService.getA({})
            .then((res: ResultA) => {
                let cat: Card[] = [];
                res.items.map(
                    (art: Art, index: number, arts: Art[]) => {
                        cat.push(
                            {
                                userId: 1,
                                id: art.cat_id,
                                title: art.cat_title,
                                body_id: art.newest.id,
                                body_title: art.newest.title,
                                body_content: art.newest.content,
                                body_more: art.newest.more
                            }
                        )
                    }
                )
                this.setState({ ...this.state, cardsOG: cat })
            }
            )
            .catch((err: any) => {
                alert('Błąd odczytu' + JSON.stringify(err));
            }
            );
    }

    componentDidMount() {
        this.getData()
    }

    reorder = (arr: Card[], layout: string, columns: number) => {
        const cols = columns;
        const out: Card[] = [];
        let col = 0;
        while (col < cols) {
            for (let i = 0; i < arr.length; i += cols) {
                let _val = arr[i + col];
                if (_val !== undefined)
                    out.push(_val);
            }
            col++;
        }
        this.setState({
            ...this.state, cardsOG: out,
            layout: layout,
            columns: columns
        });
    }

    handleButtonClick = (layout: string, columns: number) => {
        this.reorder(this.state.cardsOG, layout, columns);
    }

    handleMenuButtonClick = (item : string) => {
        alert(item)
    }

    render() {
        const classes = this.props.classes;
        return (
            <div >
                <AppBar position="static" color="default"
                    elevation={1}
                    style={{
                        borderBottom: "2px solid #020a7a",
                        padding: 0, margin: 0
                    }}>
                    <Toolbar>
                        <div className="info">
                            <div>
                                <h2>argumenty.net</h2>
                            </div>
                        </div>
                        <div className="info">
                            <a href="https://argumenty.net" target="_blank" >
                                <img src="https://argumenty.net/images/logo64.png"
                                    alt="argumenty.net"
                                    style={{ blockSize: "50%", }} />
                            </a>
                        </div>
                        <div>
&nbsp;&nbsp;&nbsp;
                        </div>

                        <div >
                            <Router>
                                <Link to="/start" onClick={
                                    () => {this.setState({
                                        layout: "masonry",
                                        subpage: 1,
                                        columns: 3,
                                        cardsOG: this.state.cardsOG
                                    });
                                 }
                                }>

    <Button
    key="root"
    color="inherit"
    className={classes.menuButton}
  >
    Argumenty
  </Button>

                                </Link>
                                
    <Button
    key="archive"
    color="inherit"
    className={classes.menuButton}
    href="https://www.argumenty.net/argumenty/new"

  >
    Wszystkie
  </Button>
                                
                                <Link to="/start#e" onClick={
                                    () => {
                                        this.setState({
                                        ...this.state,
                                        subpage: 3
                                    });
//                                    this.forceUpdate();
                                }
                                }>
{/*
                                <Button
    key="election"
    color="inherit"
    className={classes.menuButton}
  >
    Krótki Przewodnik Wyborczy
  </Button>
*/}
                                </Link>
                            </Router>
                        </div>

                    </Toolbar>
                </AppBar>
 {this.state.subpage==1?
 <Cards cards={this.state.cardsOG}
 columns={this.state.columns}
 layout={this.state.layout} />
 :
 <div></div> 
 /*<Election /> */
  }


            </div>
        );
    }

}

export default withStyles(appStyles)(App);

/*
<Route
exact
path="/"
render={() => (
    <Cards cards={this.state.cardsOG}
        columns={this.state.columns}
        layout={this.state.layout} />
)}
/>
<Route
exact
path="/v"
render={() => (
    <ContentView className="widok" title="tytuł" />
)}
/>

<Route
exact
path="/a"
render={() => (
    <Election />
)}
/>
{*
*/