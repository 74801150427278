import axiosStatic, { AxiosPromise } from 'axios';

const urlAll = 'https://argumenty.net/jarg/jarg.php?/g/a';
const urlCategory = 'https://www.argumenty.net/jarg/jarg.php?/g/d/';
/*
{
  "title":"Historia gospodarcza \u015bwiata",
  "art":[
    {"id":"2575","title":"Rockefeller nie doczeka\u0142 rz\u0105du "},
    {"id":"2291","title":"Czerwiec 1976 \u2013 mity i fakty"},
    ]
}
*/

interface IRequestConfig {
    method?: any;
    headers?: any;
    url?: any;
    data?: any;
    params?: any;
  }
 
export type Art = {
    cat_id : number,
    cat_title : string,
    newest : {
        id : number,
        title : string,
        content : string,
        more: number
    }
}
export type ArtLink = {
    id : string,
    title : string
}
export type ResultD = {
    "title":string,
    "art":ArtLink[]
}

export type ResultA = {
    totalItems : number,
    items : Art[]
}

function axios(configs: IRequestConfig): AxiosPromise {
  return axiosStatic(configs);
}

export class apiService {

  static getA(options: IRequestConfig): Promise<any> {
      return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'get' };
        configs.headers = {
          ...options.headers,
          'Content-Type': 'application/json'
        };
        let url = urlAll;
        configs.url = url;
        configs.params = { };
        let data = null;
        configs.data = data;
        axios(configs)
          .then(res => {
            resolve(res.data as ResultA);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    static getD(options: IRequestConfig, cat : string): Promise<any> {
      return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'get' };
        configs.headers = {
          ...options.headers,
          'Content-Type': 'application/json'
        };
        configs.url = urlCategory+cat;
        configs.params = { };
        let data = null;
        configs.data = data;
        axios(configs)
          .then(res => {
            resolve(res.data as ResultA);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

  }
  